/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { isActivationKey as n } from "../widgetUtils.js";
function t() {
  return function (n, t) {
    if (!n[t]) throw new TypeError(`Cannot auto bind undefined function '${String(t)}'`);
    return {
      value: r(n[t])
    };
  };
}
function e(n) {
  const t = n?.type;
  return n instanceof KeyboardEvent || "keyup" === t || "keydown" === t || "keypress" === t;
}
function r(t) {
  return function (r, ...o) {
    e(r) ? n(r.key) && (r.preventDefault(), r.stopPropagation(), r.target.click()) : t.call(this, r, ...o);
  };
}
export { t as accessibleHandler };